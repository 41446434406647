<template>
    <div class="home_box">
        <div class="h_top">
            <div class="h_row1_box">
                <div class="h_row1_b1" :class="form.status==20?'active':''" @click="change_status(20)">
                    <div class="h_row1_b2">{{countFinish}}</div>
                    <div class="h_row1_b3">{{$t('main.text270')}}</div>
                </div>
                <div class="h_row1_b1" :class="form.status==15?'active':''" @click="change_status(15)">
                    <div class="h_row1_b2">{{countProcessing}}</div>
                    <div class="h_row1_b3">{{$t('main.text281')}}</div>
                </div>
                <div class="h_row1_b1" :class="form.status==10?'active':''" @click="change_status(10)">
                    <div class="h_row1_b2">{{countScheduling}}</div>
                    <div class="h_row1_b3">{{$t('main.text282')}}</div>
                </div>
                <div class="h_row1_b1" :class="form.status==5?'active':''" @click="change_status(5)">
                    <div class="h_row1_b2">{{countWaitDeal}}</div>
                    <div class="h_row1_b3">{{$t('main.text283')}}</div>
                </div>
                <div class="h_row1_b1" :class="form.status==1?'active':''" @click="change_status(1)">
                    <div class="h_row1_b2">{{countPending}}</div>
                    <div class="h_row1_b3">{{$t('main.text284')}}</div>
                </div>
            </div>

        </div>
        <div class="h_table">
            <div class="h_table_top">
                <div class="t_top_1">{{$t('main.text50')}}</div>
                <div class="t_top_Right">
                     <div class="t_top_2">
                        <el-select v-model="form.status" :placeholder="$t('main.text108')">
                            <!-- 状态 1未开始;5待处理;10安排中;15进行中;20已完成 -->
                            <el-option :label="$t('main.text270')" value="20"></el-option>
                            <el-option :label="$t('main.text281')" value="15"></el-option>
                            <el-option :label="$t('main.text282')" value="10"></el-option>
                            <el-option :label="$t('main.text283')" value="5"></el-option>
                            <el-option :label="$t('main.text284')" value="1"></el-option>
                        </el-select>
                     </div>
                     <!-- <div class="t_top_3">
                        <el-input placeholder="输入搜索内容" v-model="form.input"></el-input>
                        <i class="el-icon-search"></i>
                     </div> -->
                     <div>
                        <el-button plain class="search_btn" @click="search">{{$t('main.text12')}}</el-button>
                        <el-button plain class="reset_btn" @click="reset">{{$t('main.text13')}}</el-button>
                     </div>
                </div>
            </div>
            <el-table
            ref="testTable"
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            @sort-change='sortChange'
            style="width: 100%">
                <el-table-column :label="$t('main.text14')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text52')">
                    <span slot-scope="props">
                        {{ props.row.order_id }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text264')" sortable="custom" prop="order_username">
                    <span slot-scope="props">
                        {{ props.row.order_username }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text265')" sortable="custom" prop="c_time">
                    <span slot-scope="props">
                        {{ time_format(props.row.c_time) }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text285')" sortable="custom" prop="interviewer_name">
                    <span slot-scope="props">
                        {{ props.row.interviewer?.name }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text286')">
                    <span slot-scope="props">
                        {{ props.row.total_count }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text92')">
                    <span slot-scope="props">
                        <!-- 状态 1未开始;5待处理;10安排中;15进行中;20已完成 -->
                        <span v-if="props.row.status==1" class="type2">{{$t('main.text266')}}</span>
                        <span v-else-if="props.row.status==5" class="type3">{{$t('main.text267')}}</span>
                        <span v-else-if="props.row.status==10" class="type3">{{$t('main.text268')}}</span>
                        <span v-else-if="props.row.status==15" class="type3">{{$t('main.text269')}}</span>
                        <span v-else-if="props.row.status==20" class="type1">{{$t('main.text270')}}</span>
                        <span v-else>-</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text21')" min-width="120">
                    <span slot-scope="props">
                        <span class="details" @click="$router.push({path:'/work_order_info',query:{order_id:props.row.order_id}})">{{$t('main.text287')}}</span>
                        <span> | </span>
                        <span class="details" @click="set_work_order(props.row)">{{$t('main.text24')}}</span>
                        <!-- <span> | </span>
                        <span class="details" v-if="props.row.status==1" @click="open_start_work(props.row.order_id)">开始</span>
                        <span class="type2" v-else>开始</span> -->
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 开启工单 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="start_work_order"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text288')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="start_work_confirm" class="confirm_btn">{{$t('main.text289')}}</el-button>
                <el-button @click="start_work_order = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
    
<script>
import {workOrder_list,start} from '@/api/api';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            form:{
                status:"",
                input:"",
            },
            start_work_order:false,
            dlg_id:"",
            orderBy:"",// 排序依据字段 0创建时间、1按创建人、2、面试官
            orderByMethod:"",// 排序方式0倒序;1正序
            countPending: 0,  //未开始数量
            countWaitDeal: 0, //待处理数量
            countScheduling: 0, //安排中数量
            countProcessing: 0, //进行中数量
            countFinish: 1, //已完成数量
        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        set_work_order(row){
            this.$router.push({path:'/edit_new_work_order',query:{work_edit_id:row.order_id,status:row.status}});
        },
        async get_list(){
            const res = await workOrder_list({
                page:this.page,
                limit:this.limit,
                status:this.form.status,
                orderBy:this.orderBy,
                orderByMethod:this.orderByMethod,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.tableData = res.data.data.list;
            this.total = res.data.data.countAll;
            this.countPending= res.data.data.countPending;  //未开始数量
            this.countWaitDeal= res.data.data.countWaitDeal; //待处理数量
            this.countScheduling= res.data.data.countScheduling; //安排中数量
            this.countProcessing= res.data.data.countProcessing; //进行中数量
            this.countFinish= res.data.data.countFinish; //已完成数量
        },
        change_status(v){
            this.form.status=v+"";
            this.search();
        },
        search(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.get_list();
        },
        reset(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.form.status="";
            this.form.input="";
            this.orderBy="";
            this.orderByMethod="";
            //清除表格排序的方法  重置排序
            this.$refs.testTable.clearSort();
            this.get_list();
        },
        open_start_work(id){
            this.dlg_id = id;
            this.start_work_order = true;
        },
        async start_work_confirm(){
            const res = await start({
                order_id:this.dlg_id,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.start_work_order = false;
            this.get_list();
        },
        sortChange( {prop, order}){
            // orderBy 排序依据字段 0创建时间、1按创建人、2、面试官
            // orderByMethod 排序方式0倒序;1正序
            if(order=='descending'){//降序
                this.orderByMethod=0;
            }else if(order=='ascending'){//升序
                this.orderByMethod=1;
            }else{//默认
                this.orderByMethod="";
            }
            if(prop=='c_time'){
                this.orderBy=0;
            }else if(prop=='order_username'){
                this.orderBy=1;
            }else if(prop=='interviewer_name'){
                this.orderBy=2;
            }else{
                this.orderBy="";
            }
            this.get_list();
        }

    },

}
</script>
    
<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}
.h_top{
    margin-bottom: 25px;
}
.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
/* --- */
.h_row1_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.h_row1_b1{
    flex: auto;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 30px;
    cursor: pointer;
}
.h_row1_b2{
    color: #ED1F44;
    font-size: 30px;
    font-weight: 500;
}
.h_row1_b3{
    color: #838383;
    font-size: 16px;
    margin-top: 16px;
}
.active{
    border: 1px solid #ED1F44;
}
</style>
